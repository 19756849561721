import gsap, { Elastic } from 'gsap';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Container, TitleWithBg } from '../../../../Components';
import Oldwoman from '../../../../Assets/images/old_woman.jpeg';
import { constants } from './constants';
import ContentBox from './ContentBox';

const VALUE_PROP = [
	{
		text: 'Pi-health aids ageing population and complex patients in automated health monitoring of their wards.',
	},
	{
		text: 'Pi-health can predict patient conditions in advance by fusing symptom monitoring algorithms powered by AI.',
	},
	{
		text: 'The Clinicians’ portal not only helps manage patient’s symptoms remotely but also provides severity stratification of physiological symptoms and remote triage.',
	},
	{
		text: 'Pi-health helps physicians increase revenue streams through CPT codes 99457 and 99458, which reimburse physicians for time spent on remote care activities such as reviewing patient data.',
	},
	{
		text: 'Pi-health data communication system aids streamlined communication between caregivers and the patient’s medical network for more productive patient management.',
	},
];

const HowItWorks = () => {
	const parentRef = useRef();
	const [active] = useState(false);

	useEffect(() => {
		let ctx = gsap.context(() => {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							gsap.to('.child', {
								y: 0,
								opacity: 1,
								duration: 1,
								ease: Elastic.easeOut.config(1, 0.3),
							});
							gsap.to(`.${entry.target.classList[0]} .leftText`, {
								x: 0,
								opacity: 1,
								duration: 2.5,
								delay: 0.5,
								ease: Elastic.easeOut.config(1, 0.3),
							});
							gsap.to(
								`.${entry.target.classList[0]} .cont-image`,
								{
									x: 0,
									opacity: 1,
									duration: 2.5,
									ease: Elastic.easeOut.config(1, 0.3),
								}
							);
						} else return;
					});
				},
				{
					threshold: window.innerWidth > 768 ? 0.6 : 0.4,
				}
			);
			Array.from(parentRef.current.children).forEach((child) =>
				observer.observe(child)
			);
		}, parentRef);

		return () => ctx.revert();
	}, [active]);

	return (
		<section
			id="how-it-works"
			className="w-screen h-max px-[4%] bg-[#F2F2F2]"
		>
			<div className="[@media(min-width:1120px)]:mb-20 flex relative flex-col items-center min-h-[600px]">
				{/* Main content */}
				<div className="z-10 isolate pt-12 flex w-full mx-auto pb-16 max-w-[1440px] flex-col items-center">
					{/* Header */}
					<TitleWithBg>Value Proposition</TitleWithBg>

					{/* Content */}
					<div className="flex items-start mt-12 [@media(min-width:1120px)]:flex-row flex-col">
						<div className="flex flex-1 [@media(min-width:1120px)]:flex-none rounded-[10px] h-full overflow-x-hidden">
							<div className="flex flex-col h-full">
								<div className="flex max-w-[600px] rounded-[10px] overflow-x-hidden">
									<span className="hidden [@media(min-width:1120px)]:flex items-center p-[3px] pr-0 min-w-[48px] rounded-l-[10px] bg-gradient-to-r from-[#4B4FBB] to-[#C04C27] min-h-[80%] my-auto">
										<div className="min-w-full block min-h-[320px] rounded-l-[8px] bg-[#F2F2F2]" />
									</span>
									<img
										src={Oldwoman}
										className="object-cover w-full min-h-[300px] [@media(min-width:1120px)]:min-h-[500px] rounded-[10px]"
										alt="an-old-woman"
									/>
								</div>
								<p className="rounded-[10px] mx-auto [@media(min-width:1120px)]:ml-20 text-sm [@media(min-width:500px)]:text-base [@media(min-width:1120px)]:text-[23px] w-[80%] mt-12 text-center font-semibold bg-white py-4 px-4 [@media(min-width:1120px)]:px-8">
									Pi-health, your healthy ageing partner
								</p>
							</div>
						</div>
						{/* Text content */}
						<div className="flex w-full flex-1 mx-auto flex-col [@media(min-width:1120px)]:mt-0 mt-12 px-8 justify-center items-center">
							{/* Icon */}
							{VALUE_PROP.map((value, idx) => (
								<div
									aria-disabled={idx === 4}
									className="max-w-[600px] [&[aria-disabled='true']]:border-none relative pl-8 border-l pb-6 flex gap-1 items-start"
									key={idx}
								>
									<span className="min-w-[20px] absolute block -left-2 translate-y-1">
										<svg
											width="16"
											height="16"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="10"
												cy="10"
												r="9.5"
												stroke="black"
											/>
											<circle
												cx="10"
												cy="10"
												r="5"
												fill="black"
											/>
										</svg>
									</span>

									<p className="text-black text-sm [@media(min-width:500px)]:text-base">
										{value.text}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<Container>
				<>
					<TitleWithBg>How it Works</TitleWithBg>
					<div
						ref={parentRef}
						className={`py-16 flex w-full mx-auto items-center lg:items-center flex-col flex-wrap gap-16 lg:gap-[140px]`}
					>
						{!active
							? constants.map((constant, index) => {
									return (
										<ContentBox
											parentRef={parentRef}
											key={constant.tag}
											constant={constant}
											index={index}
										/>
									);
							  })
							: null}
					</div>
				</>
			</Container>
		</section>
	);
};

export default HowItWorks;
