import React from 'react';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { Subscribe } from '../../Pages/Home/Components';

const Layout = ({ children }) => {
	return (
		<div className="w-screen overflow-x-hidden">
			<Navbar />
			<div className="pb-28">{children}</div>
			{window.location.pathname.includes('/contact') ? null : (
				<Subscribe />
			)}
			<Footer />
		</div>
	);
};

export default Layout;
