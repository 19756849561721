import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Carousel = ({ product }) => {
	return (
		<HashLink to={product.link} smooth>
			<div className="flex flex-col lg:min-w-[620px] bg-white min-w-[340px] max-h-max mb-3 shadow p-4 snap-center rounded-[10px] gap-4">
				<div className="w-full p-4 bg-slate-100">
					<img
						src={product?.image}
						className="w-full object-contain md:min-h-[340px] max-h-[340px]"
						alt="Carousel"
					/>
				</div>
				<h1 className="font-[600] text-center text-[20px]">
					{product?.title}
				</h1>
				<p className="font-[400] font-Nunito text-center text-black/60 text-[16px]">
					{product?.subtitle}
				</p>
			</div>
		</HashLink>
	);
};

export default Carousel;
