import CarouselImageTwo from '../../../../Assets/images/carousel-image-02.jpg';
import CarouselImageFour from '../../../../Assets/images/carousel-image-04.jpg';
import VitalWatch from '../../../../Assets/images/vital-watch.svg';
import PressureWatch from '../../../../Assets/images/pressure-watch.jpg';
import Ring from '../../../../Assets/images/ring.jpg';
import AtrialBelt from '../../../../Assets/images/atrial-belt.svg';

export const PRODUCTS = [
	{
		title: 'ECG Patch',
		subtitle:
			'Continuously monitor and record your heart rate levels for efficient post-surgery health management and during heart ailments',

		image: CarouselImageTwo,
		link: `products/pi-health/#ecg-patch`,
	},
	{
		title: 'Smart Sleep Monitor',
		subtitle:
			'Receive valuable insights into your sleep patterns and quality',

		image: CarouselImageFour,
		link: `products/pi-health/#sleep-monitor`,
	},
	{
		title: 'Vital Watch',
		subtitle: 'Monitors your heart rate and overall body activities',

		image: VitalWatch,
		link: `products/pi-health/#sleep-monitor`,
	},
	{
		title: 'Blood Pressure Monitoring Watch',
		subtitle:
			'Stay informed about your cardiovascular health effortlessly with our Blood Pressure Monitoring Watch, offering real-time readings and trends.',

		image: PressureWatch,
		link: `products/pi-health/#sleep-monitor`,
	},
	{
		title: 'Atrial Fibrillation Belt',
		subtitle: 'Monitors your heart rate and overall body activities',

		image: AtrialBelt,
		link: `products/pi-health/#sleep-monitor`,
	},
	{
		title: 'Smart Health Ring',
		subtitle:
			"Pihealth's Smart Ring for diabetic patients empowers you to take control of your health effortlessly.",

		image: Ring,
		link: `products/pi-health/#smart-ring`,
	},
];
