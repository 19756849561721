const routes = {
	root: '/',
	services: '/services',
	piHealthProducts: '/products/pi-health',
	piHealthStakholders: '/stakeholders/pi-health',
	features: '/features',
	howItWorks: '/how-it-works',
	contact: '/contact',
	bundles: '/bundles',
};

export default routes;
