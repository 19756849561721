import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Skeleton } from '../Components';
import ScrollToTop from '../Utils/ScrollToTop';
import routes from './routes.const';

const Home = lazy(() => import('../Pages/Home'));
const PiHealthProducts = lazy(() => import('../Pages/PiHealthProducts'));
const Contact = lazy(() => import('../Pages/Contact'));
const Features = lazy(() => import('../Pages/Features'));
const PiHealthStakeholders = lazy(() => import('../Pages/PiHealthStakeholder'));
const HowItWorks = lazy(() => import('../Pages/HowItWorks'));
const Bundles = lazy(() => import('../Pages/Bundles'));

const App = () => {
	return (
		<BrowserRouter>
			<div className="max-w-screen overflow-x-hidden">
				<Suspense fallback={<Skeleton />}>
					<ScrollToTop />
					<Routes>
						<Route path={routes.root} element={<Home />} />
						<Route path={routes.features} element={<Features />} />
						<Route
							path={routes.piHealthProducts}
							element={<PiHealthProducts />}
						/>
						<Route
							path={routes.piHealthStakholders}
							element={<PiHealthStakeholders />}
						/>
						<Route path={routes.bundles} element={<Bundles />} />
						<Route
							path={routes.howItWorks}
							element={<HowItWorks />}
						/>
						<Route path={routes.contact} element={<Contact />} />
					</Routes>
				</Suspense>
			</div>
		</BrowserRouter>
	);
};

export default App;
