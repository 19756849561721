import React from 'react';

const ContentBox = ({ constant, index }) => {
	let flexRow;
	const evaluateStyling = () => {
		if (index % 2 === 0) {
			flexRow = 'lg:flex-row-reverse';
		} else {
			flexRow = 'lg:flex-row justify-between lg:gap-[100px]';
		}
		return flexRow;
	};

	return (
		<div
			className={`work-${index} flex ${evaluateStyling()} flex-wrap lg:w-[80%] flex-col-reverse gap-12`}
		>
			{constant.list && window.innerWidth <= 768 && (
				<ul className="leftText pl-8 translate-x-[50px] opacity-0 lg:translate-y-0 -translate-y-[40px]">
					{constant.list.map((item) => (
						<li
							className="text-[16px] font-Nunito pl-4 lg:max-w-[500px] leading-7 font-[400] list-disc"
							key={item}
						>
							{item}
						</li>
					))}
				</ul>
			)}
			{window.innerWidth <= 768 && (
				<p className="leftText text-[16px] translate-x-[50px] opacity-0 lg:translate-y-0 -translate-y-[32px]  font-Nunito max-w-[450px] leading-6 font-[500]">
					{constant.subTitle}
				</p>
			)}

			<div className="cont-image -translate-x-[50px] opacity-0 max-w-[462px] bg-gray-100/50 flex items-center lg:translate-y-0 -translate-y-[20px] min-w-[300px] flex-1 max-h-[667px]">
				<img
					src={constant.image}
					className={`object-contain ${
						index === 1 && 'rounded-none'
					}`}
					alt={constant.title}
				/>
			</div>
			<div className="leftText opacity-0 flex flex-1 min-w-[300px] translate-x-[50px] flex-col gap-6 justify-center">
				<div
					className={`${constant.tagColor}  lg:mx-0 mx-auto text-white rounded-[20px] w-[93px] py-2 flex items-center justify-center text-[16px] font-[600]`}
				>
					{constant.tag}
				</div>
				<h2 className=" lg:text-[28px] text-[20px] lg:text-left text-center font-[700]">
					{constant.title}
				</h2>
				{window.innerWidth > 768 && (
					<p className=" text-[16px] font-Nunito max-w-[450px] leading-6 font-[500]">
						{constant.subTitle}
					</p>
				)}

				{/* If the content contains a list */}
				{constant.list && window.innerWidth > 768 && (
					<ul className="leftText pl-8">
						{constant.list.map((item) => (
							<li
								className="text-[16px] font-Nunito pl-4 lg:max-w-[500px] leading-7 font-[400] list-disc"
								key={item}
							>
								{item}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};

export default ContentBox;
