import React from 'react';
import windowResizerListener from '../../Utils/helper';
import routes from '../../Routes/routes.const';
import { HashLink } from 'react-router-hash-link';
/**
 *
 * @tagNumber - The number prefix for the title of the infographic container
 * @title - The title at the top of the infographic container
 * @imageSrc - The image source in the infographic container
 * @text - The paragraph after the image in the infographic container, could be a text or a list
 * @index - A non compulsory prop for the intents of gsap animation - helps to identify each container uniquely
 *
 */

const InfoGraphic = ({
	tagNumber,
	title,
	imageSrc,
	text,
	index,
	identifier,
	products,
	link,
}) => {
	const { windowWidth } = windowResizerListener();

	const routesToAdjust = ['/bundles'].includes(window.location.pathname);

	return (
		<>
			{windowWidth >= 768 ? (
				<div
					className={`${
						identifier + '_' + index
					} flex w-full justify-between items-center ${
						index % 2 === 1 ? 'flex-row-reverse' : 'flex-row'
					} gap-16 max-w-[1280px] mx-auto`}
				>
					<div className="health_image -translate-y-20 opacity-0  flex-1  max-w-[620px] overflow-hidden">
						{link ? (
							<a href={link}>
								<img
									className="min-w-full h-full object-contain"
									src={imageSrc || ''}
									alt=""
								/>
							</a>
						) : (
							<img
								className="min-w-full h-full object-contain"
								src={imageSrc || ''}
								alt=""
							/>
						)}
					</div>

					<div
						className={`flex ${
							index % 2 === 1
								? ''
								: routesToAdjust
								? 'lg:pl-40'
								: null
						} flex-col gap-2 max-w-[620px] flex-1`}
					>
						<div className="flex gap-2 items-center">
							<span className="tag -translate-x-10 opacity-0 text-[18px] font-[600] ">
								{tagNumber || '01'}.
							</span>
							<h2 className="title_info translate-x-5 opacity-0 text-[20px] font-[600] ">
								{title || 'Pi Health'}
							</h2>
						</div>
						{typeof text === 'string' && (
							<p className="text_info -translate-x-20 opacity-0 text-base font-Nunito font-[400] text-[#131313CC]">
								{text || 'Some text'}
							</p>
						)}
						{typeof text === 'object' && (
							<ul
								className={`list_info w-full translate-x-20 opacity-0 list-disc px-4 text-base font-[500] mt-4 flex flex-col gap-3`}
							>
								{text?.map((item, index) => {
									if (item.id && item.id !== null) {
										return (
											<HashLink
												key={item.label + index}
												smooth
												to={`${routes.piHealthProducts}/#${item.id}`}
												className="underline cursor-pointer"
											>
												<li className="pl-3 font-Nunito text-blue font-[400]">
													{item?.label || item}
												</li>
											</HashLink>
										);
									} else
										return (
											<li
												key={item.label + index}
												className="pl-3 font-Nunito  text-[#131313CC] font-[400]"
											>
												{item.label || item}
											</li>
										);
								})}
							</ul>
						)}
					</div>
				</div>
			) : (
				<div
					className={`${
						identifier + '_' + index
					} flex w-full md:flex-row flex-col gap-4`}
				>
					<div className="flex gap-2 items-center">
						<span className="tag -translate-x-10 opacity-0 text-[18px] font-[600] ">
							{tagNumber || '01'}.
						</span>
						<h2 className="title_info translate-x-5 opacity-0 text-[20px] font-[600] ">
							{title || 'Pi Health'}
						</h2>
					</div>
					<div className="health_image -translate-y-20 opacity-0 h-max overflow-hidden">
						{link ? (
							<a href={link}>
								<img
									className="min-w-full h-full object-contain"
									src={imageSrc || ''}
									alt=""
								/>
							</a>
						) : (
							<img
								className="min-w-full h-full object-contain"
								src={imageSrc || ''}
								alt=""
							/>
						)}
					</div>
					{typeof text === 'string' && (
						<p className="text_info -translate-x-20 opacity-0 text-base font-Nunito font-[400] text-[#131313CC] ">
							{text || 'Some text'}
						</p>
					)}
					{typeof text === 'object' && (
						<ul className="list_info w-full translate-x-20 opacity-0 px-4 list-disc text-base font-[500] mt-4 flex flex-col gap-6">
							{text?.map((item, index) => {
								if (item.id && item.id !== null) {
									return (
										<HashLink
											key={item.label + index}
											smooth
											to={`${routes.piHealthProducts}/#${item.id}`}
											className="text-blue underline cursor-pointer"
										>
											<li className="pl-3 font-Nunito  text-blue font-[400]">
												{item?.label || item}
											</li>
										</HashLink>
									);
								} else
									return (
										<li
											key={
												item?.label + index ||
												item + index
											}
											className="pl-3 font-Nunito  text-[#131313CC] font-[400]"
										>
											{item?.label || item}
										</li>
									);
							})}
						</ul>
					)}
					{products && (
						<div
							className={`${
								identifier + '_' + index + 'products'
							} flex w-full md:flex-row flex-col gap-4`}
						>
							<div className="health_image -translate-y-20 opacity-0 max-h-[300px] overflow-hidden">
								<img
									className="min-w-full h-full object-contain"
									src={products.imageSrc || ''}
									alt=""
								/>
							</div>

							<p className="text_info -translate-x-20 opacity-0 text-base font-Nunito font-[400] text-[#131313CC] ">
								{text || 'Some text'}
							</p>

							<div className="health_image -translate-y-20 opacity-0 max-h-[300px] overflow-hidden">
								<img
									className="min-w-full h-full object-contain"
									src={products.imageTwo || ''}
									alt=""
								/>
								{products.imageText && (
									<i className="font-bold opacity-60 text-xs">
										{products.imageText}
									</i>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default InfoGraphic;
