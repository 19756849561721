import gsap, { Elastic } from 'gsap';
import React, { useEffect, useRef } from 'react';
import { CarouselNavs } from '../CarouselNavs';
import { Container, TitleWithBg } from '../../../../Components';
import Carousel from './Carousel';

import { PRODUCTS } from './productsData';

const OurProducts = () => {
	const carouselRef = useRef();
	const parentRef = useRef();

	useEffect(() => {
		let ctx = gsap.context(() => {
			const observer = new IntersectionObserver(
				(entries) => {
					if (entries[0].isIntersecting) {
						gsap.to('.child', {
							y: 0,
							opacity: 1,
							duration: 2,
							ease: Elastic.easeOut.config(1, 0.3),
						});
						setTimeout(() => {
							gsap.to('.main', {
								x: 0,
								opacity: 1,
								duration: 2,
								ease: Elastic.easeOut.config(1, 0.3),
							});
						}, 300);
					}
				},
				{
					threshold: 0.6,
				}
			);

			observer.observe(parentRef.current);
		}, parentRef);

		return () => ctx.revert();
	}, []);

	return (
		<section ref={parentRef} className="w-screen h-max bg-[#f2f2f2]">
			<Container>
				<div>
					<TitleWithBg>Our Products</TitleWithBg>
					<div className="main translate-x-[10vw] opacity-0 mt-16 flex flex-col w-full ">
						<div
							ref={carouselRef}
							className=" w-full flex scrollbar px-8 overflow-x-hidden snap-x mx-auto gap-20"
						>
							{PRODUCTS.map((product, index) => {
								return (
									<Carousel key={index} product={product} />
								);
							})}
						</div>
						<CarouselNavs
							carouselRef={carouselRef}
							PRODUCTS={PRODUCTS}
						/>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default OurProducts;
