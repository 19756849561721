// import MainSectionImageOne from '../../../../Assets/images/how-it-works-1.jpg';
import MainSectionImageTwo from '../../../../Assets/images/hero-image-07.jpg';
import MainSectionImageThree from '../../../../Assets/images/wearable.png';

const constants = [
	{
		image: MainSectionImageThree,
		tag: '01',
		tagColor: 'bg-Pigreen',
		title: 'Wearables',
		subTitle: 'The wearable performs functions such as:',
		list: [
			'Symptom monitoring',
			'New insights into disease',
			'Emergency Notification',
			'Precision Medicine',
			'Severity Stratification',
			'Disease Identificaton & Al assisted diagnosis',
			'Sleep Apnea',
			'Blood Pressure Management',
			'Heart diseases',
			'Atrial Fibrillation',
		],
	},
	{
		image: MainSectionImageTwo,
		tag: '02',
		tagColor: 'bg-Piorange',
		title: 'Mobile and Web App',
		subTitle:
			'Machine learning algorithms fuses with symptoms monitoring from the wearable to create actionable insights thereby leading to healthy ageing and decision intelligence for clinicians.',
		list: [
			'Alert',
			'Machine learning algorithms',
			'Interface for carers',
			'Interface for clinicians (multipatient monitoring)',
			'Interface for family',
			'Market place for healthy ageing products',
			'Activity alert ',
			'AI backed symptom monitoring',
			'Fall detection',
			'Post surgery management for physiological symptoms',
		],
	},
];

export { constants };
