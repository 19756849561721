import Heart from '../../Assets/svg/heart.svg';
import routes from '../../Routes/routes.const';

export const heart = Heart;

export const LOGO = {
	label: 'Pi-Health',
	route: routes.root,
	icon: Heart,
};

const NAV_CONTENT = [
	{
		label: 'Home',
		route: routes.root,
	},

	{
		label: 'Products',
		route: routes.piHealthProducts,
	},
	{
		label: 'Stakeholders',
		route: routes.piHealthStakholders,
	},
	{
		label: 'Bundles',
		route: routes.bundles,
	},
	{
		label: 'Contact Us',
		route: routes.contact,
	},
];

export default NAV_CONTENT;
