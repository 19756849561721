/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef } from 'react';
import { Button, Container } from '../../../../Components';
import routes from '../../../../Routes/routes.const';
import HeroImageOne from '../../../../Assets/images/HeroImage.webp';
import HeroImageTwo from '../../../../Assets/images/oldHuman-1.webp';
import HeroImageThree from '../../../../Assets/images/oldHuman-2.webp';
import HeroImageFour from '../../../../Assets/images/oldHuman-3.webp';
import HeroImageFive from '../../../../Assets/images/oldHuman-4.webp';

import slideEffect from '../../../../Utils/slideEffect';
import gsap, { Elastic } from 'gsap';

const IMAGES = [
	{
		image: HeroImageOne,
		alt: 'elderly',
	},
	{
		image: HeroImageTwo,
		alt: 'elderly',
	},
	{
		image: HeroImageThree,
		alt: 'elderly',
	},
	{
		image: HeroImageFour,
		alt: 'elderly',
	},
	{
		image: HeroImageFive,
		alt: 'elderly',
	},
];

const Hero = () => {
	const heroRef = useRef();
	const textRef = useRef();

	slideEffect(heroRef, 5000);

	useEffect(() => {
		let ctx = gsap.context(() => {
			gsap.from('h2', {
				opacity: 0,
				y: 100,
				ease: Elastic.easeOut.config(1, 0.3),
				duration: 2,
			});
			gsap.from('p', {
				opacity: 0,
				y: 100,
				delay: 1,
				ease: Elastic.easeOut.config(1, 0.3),
				duration: 2,
			});
			gsap.from('.button', {
				opacity: 0,
				y: 100,
				delay: 1.5,
				ease: Elastic.easeOut.config(1, 0.3),
				duration: 2,
			});
		}, textRef);

		return () => ctx.revert();
	}, []);

	return (
		<section className="relative z-[10] md:max-h-[1024px] h-screen w-screen">
			<div
				ref={heroRef}
				className="lg:h-full flex w-full min-h-full z-[-1] display overflow-x-scroll absolute"
			>
				{IMAGES.map((image) => {
					return (
						<img
							loading="lazy"
							key={image.alt}
							className="min-w-[100vw] object-cover min-h-full"
							src={image.image}
							alt={image.alt}
						/>
					);
				})}
			</div>
			<div className="h-full flex  w-full linear-gradient z-[-1] absolute"></div>
			<Container>
				<div
					ref={textRef}
					className="flex flex-col gap-6 md:gap-10 h-screen justify-center"
				>
					<h2 className="box text-white text-[24px] leading-9 font-[600] max-w-[680px] lg:max-w-[650px] lg:leading-[45px] lg:text-[40px]">
						AI powered monitoring tool for over 60s<sup>,</sup> with
						Co-morbidities.
					</h2>
					<p className="circle lg:text-[18px] font-Nunito text-[16px] text-white font-[400] leading-[28px] tracking-wide w-[90%] lg:max-w-[560px]">
						PI-Health is a remote monitoring tool for over 60s’ with
						co-morbidities that provides symptom monitoring along
						with AI backed risk stratified decision intelligence
						that allows Clincians, Carers and Family make faster and
						better decision concerning their wards.
					</p>
					<div className="button flex gap-8 mt-3 items-center">
						<Button
							type="button"
							variant="secondary"
							role="link"
							route={routes.piHealthProducts}
						>
							Learn More
						</Button>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default Hero;
